import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: ` <footer>
    <div class="footer-content" [attr.aria-label]="messages.footer" id="footer">
      <nav class="link-wrapper" aria-labelledby="footer">
        <a
          href="https://www.sick.com/imprint/w/imprint/"
          i18n="@@footer.imprint"
          class="syn-link syn-link--medium syn-link--quiet"
        >
          Imprint
        </a>
        <a
          href="https://www.sick.com/de/en/w/tac"
          i18n="@@footer.termsAndConditions"
          class="syn-link syn-link--medium syn-link--quiet"
        >
          Terms and Conditions
        </a>
        <a
          href="https://www.sick.com/de/en/w/terms-of-use"
          i18n="@@footer.termsOfUse"
          class="syn-link syn-link--medium syn-link--quiet"
        >
          Terms of Use
        </a>
        <a
          href="https://www.sick.com/de/en/w/dataprotection"
          i18n="@@footer.privacyPolicy"
          class="syn-link syn-link--medium syn-link--quiet"
        >
          Privacy Policy
        </a>
      </nav>
      <p class="copyright">© {{ year }} SICK AG</p>
    </div>
  </footer>`,
  styles: [
    `
      footer {
        background-color: var(--syn-color-neutral-950);
        container-type: inline-size;
        overflow-y: hidden;
      }

      .footer-content {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: var(--syn-spacing-x-large);
        justify-content: space-between;
        padding: var(--syn-spacing-large) var(--syn-spacing-2x-large);
      }

      .link-wrapper {
        display: flex;
        gap: var(--syn-spacing-large);
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
      }

      a {
        --syn-link-quiet-color: var(--syn-typography-color-text-inverted);
        --syn-link-quiet-color-hover: var(--syn-color-neutral-300);
        --syn-link-quiet-color-active: var(--syn-color-neutral-100);
      }

      .copyright {
        color: var(--syn-typography-color-text-inverted);
        font: var(--syn-body-small-bold);
        margin: 0;
      }

      @container (max-width: 640px) {
        .footer-content {
          align-items: flex-start;
          flex-direction: column;
          padding: var(--syn-spacing-large) var(--syn-spacing-medium);
        }

        .link-wrapper {
          flex-direction: column;
          gap: var(--syn-spacing-small);
        }
      }
    `,
  ],
  standalone: true,
})
export class FooterComponent {
  year = new Date().getFullYear();
  messages = {
    footer: $localize`:@@footer:Footer`,
  };
}
