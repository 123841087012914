import { ApplicationConfig, inject, provideAppInitializer } from '@angular/core';
import { ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { routes } from './app.routes';
import { AuthInterceptor } from './auth/interceptor';
import { GroupsEffects } from './groups/store/groups.effects';
import { groupsFeature } from './groups/store/groups.feature';
import { GroupDetailEffects } from './groups/store/group-details/group-details.effects';
import { ERROR_LISTENERS } from './shared/error-handling/error-listener/error-listener';
import { HttpErrorListener } from './shared/error-handling/error-listener/http-error-listener';
import { GlobalErrorHandler } from './shared/error-handling/global-error-handler';
import { environment } from '../environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';
import { I18n, setLocaleId } from './i18n';
import { StateService } from './shared/store/state';
import { initAuthentication } from './auth/auth';

function devTools() {
  if (environment.production) {
    return [];
  }
  return [StoreDevtoolsModule.instrument({})];
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      // StoreModule and EffectsModule need to be forRoot()'ed before we call forFeature()!
      // Otherwise we get: ERROR NullInjectorError: No provider for _Store
      StoreModule.forRoot(),
      EffectsModule.forRoot(),

      // Previously part of groups module
      StoreModule.forFeature(groupsFeature),
      EffectsModule.forFeature([GroupsEffects, GroupDetailEffects]),

      devTools()
    ),

    provideAnimations(),
    provideRouter(routes),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    provideAppInitializer(async () => {
      const i18n = inject(I18n);
      const state = inject(StateService);
      await initAuthentication(state);
      await i18n.setLocale();
    }),
    setLocaleId(),

    // Previously part of shared module
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: ERROR_LISTENERS, useClass: HttpErrorListener, multi: true },
  ],
};
