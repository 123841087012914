import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  input,
  model,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type OnChangeFn = (inputText: string) => unknown;
type OnTouchedFn = () => unknown;

@Component({
  selector: 'app-text-field',
  template: ` <syn-input
    [attr.placeholder]="placeholder()"
    [label]="label()"
    [attr.disabled]="disabled() ? true : null"
    [attr.readonly]="readonly() ? true : null"
    [attr.error]="error() ? true : null"
    [attr.help-text]="error()"
    [attr.required]="required()"
    [value]="text()"
    autocomplete="off"
    (syn-input)="onInput($event)"
    (syn-blur)="onBlur($event)"
    #element
  ></syn-input>`,
  styles: [
    `
      syn-input {
        width: 100%;
        margin: 0;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextFieldComponent,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [],
})
export class TextFieldComponent implements ControlValueAccessor, OnChanges {
  placeholder = input('');
  label = input('');
  disabled = model(false);
  readonly = input(false);
  error = input<string | null>(null);
  required = input(false);
  text = model('');

  @ViewChild('element') element: ElementRef<HTMLInputElement> | undefined;

  private isTouched = false;
  private onChange: OnChangeFn = () => {};
  private onTouched: OnTouchedFn = () => {};

  writeValue(inputText: string) {
    if (inputText === '') {
      this.onFormControlReset();
    }
    this.text.update(() => inputText);
  }

  private onFormControlReset() {
    this.isTouched = false;
    this.disabled.update(() => false);
  }

  registerOnChange(onChange: OnChangeFn): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: OnTouchedFn): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled.update(() => isDisabled);
  }

  onInput(event: Event) {
    if (event.target === this.element?.nativeElement) {
      const newValue = (event.target as HTMLInputElement).value;
      this.text.update(() => newValue);
      this.onChange(newValue);
    }
  }

  onBlur(event: Event) {
    if (event.target === this.element?.nativeElement) {
      if (this.isTouched) return;
      this.onTouched();
      this.isTouched = true;
    }
  }

  // Since synergy seems to not offer a attribute to mark a component as invalid
  // we have to use the setCustomValidity function to mark a component.
  // What we would want to have:
  // <syn-textarea [attr.invalid]="true/false" >
  // Currently waiting to see whether the synergy people can provide a better method:
  // https://github.com/synergy-design-system/synergy-design-system/issues/574
  ngOnChanges(changes: SimpleChanges): void {
    const errorChange = changes['error'];
    if (errorChange) {
      // The only way I found to mark an element as invalid is the setCustomValidity function.
      // An empty string marks the element as valid, a non-empty string as invalid
      this.element?.nativeElement?.setCustomValidity(this.error() ?? '');

      // The reportValidity function shows a strange popup (probably HTML5 stuff?)
      // We do not want it for now.
      // this.element?.nativeElement?.reportValidity();

      // When the validity is first reported as false, the synergy field is not marked red
      // If we click on another component, i.e. lose focus, it gets marked red.
      // Therefore, we trigger this reaction by losing focus and immediatly regaining focus.
      // This might cause some undesired behavior for the user.
      this.element?.nativeElement?.blur();
      this.element?.nativeElement?.focus();
    }
  }
}
